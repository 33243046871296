
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-row>
          <el-col :span="24">
            <el-form-item label="单位" prop="companyId">
              <el-select-tree
                :props="props"
                :options="companyResult"
                v-model="formModel.companyId"
                width="360px"
                size="mediumn"
              ></el-select-tree>&nbsp;
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input v-model="formModel.deviceNo" placeholder="请输入设备编号" style="width:360px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备厂家" prop="manufacturer">
              <el-select v-model="formModel.manufacturer" style="width:80%">
                <el-option v-for="(item,index) in manufacturerList" 
                :label="item.name" :value="item.value" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备别名" prop="aliasName">
              <el-input v-model="formModel.aliasName" placeholder="请输入设备别名" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="模块类型" prop="type">
              <el-select v-model="formModel.type" filterable placeholder="请选择" style="width:80%">
                <el-option
                  v-for="result in moduleTypeList"
                  :key="result.id"
                  :label="result.name"
                  :value="result.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>          
          <el-col :span="12">
            <el-form-item label="设备类型" prop="deviceType">
              <el-select v-model="formModel.deviceType" style="width:80%">
                <el-option v-for="(item,index) in deviceTypeList" 
                :label="item.name" :value="item.value" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
        <el-form-item label="立柱颜色" prop="columnColor">
          <el-select v-model="formModel.columnColor" style="width:80%">
            <el-option v-for="(item,index) in columnColorList" 
            :label="item.name" :value="item.value" :key="index"></el-option>
          </el-select>
        </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="立柱高度" prop="columnHeight">
              <el-select v-model="formModel.columnHeight" style="width:80%">
                <el-option v-for="(item,index) in columnHeightList" 
                :label="item.name" :value="item.value" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="内部编号" prop="code">
              <el-input type="text" v-model="formModel.code" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="场景值" prop="type">
              <el-select v-model="formModel.deviceAddress" filterable placeholder="请选择" style="width:80%">
                <el-option
                  v-for="result in deviceAddressData"
                  :key="result.id"
                  :label="result.name"
                  :value="result.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="开锁密码" prop="unlockPassword">
              <el-input v-model="formModel.unlockPassword" placeholder="请输入开锁密码" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="二维码地址" prop="codeAddress">
              <el-input v-model="formModel.codeAddress" placeholder="请输入二维码地址" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import deviceInfoApi from "@/api/base/deviceInfo";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        aliasName: [
          { required: true, message: "设备别名不能为空", trigger: "blur" }
        ],
        // isOnline: [
        //   {
        //     required: true,
        //     message: "运行状态不能为空",
        //     trigger: "blur"
        //   }
        // ],
        // unlockPassword: [
        //   { required: true, message: "开锁密码不能为空", trigger: "blur" }
        // ],
        // codeAddress: [
        //   { required: true, message: "二维码地址不能为空", trigger: "blur" }
        // ],
        // ipAddress: [
        //   { required: true, message: "ip地址不能为空", trigger: "blur" }
        // ],
        deviceNo: [{ required: true, message: "设备编号不能为空", trigger: "blur" }],
        type: [{ required: true, message: "模块类型不能为空", trigger: "blur" }],
        manufacturer: [{ required: true, message: "设备厂家不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      canQuery: true,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      deviceAddressData:[],      
      columnColorList: [],
      columnHeightList: [],
      moduleTypeList: [],
      deviceTypeList: [],
      manufacturerList: []
    };
  },
  created() {
    this.loadTree();

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "设备场景值"
    }).then(response => {
      var jsonData = response.data;
      this.deviceAddressData = jsonData.data;
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "立柱颜色"
    }).then(response => {
      var jsonData = response.data;
      this.columnColorList = jsonData.data;
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "立柱高度"
    }).then(response => {
      var jsonData = response.data;
      this.columnHeightList = jsonData.data;
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "模块类型"
    }).then(response => {
      var jsonData = response.data;
      this.moduleTypeList = jsonData.data;
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "设备类型"
    }).then(response => {
      var jsonData = response.data;
      this.deviceTypeList = jsonData.data;
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "设备厂家"
    }).then(response => {
      var jsonData = response.data;
      this.manufacturerList = jsonData.data;
    });
  },
  methods: {
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            self.loading = true;

            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return deviceInfoApi.add(self.formModel);
            } else {
              return deviceInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            self.loading = false;
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    queryDeviceNo() {
      var self = this;
      if (self.canQuery) {
        self.canQuery = false;
        var formData = new FormData();
        formData.append("ipAddress", self.formModel.ipAddress);
        formData.append("port", self.formModel.port);
        deviceInfoApi.getByIpAddressAndPort(formData).then(function(response) {
          var jsonData = response.data;
          setTimeout(() => {
            self.canQuery = true;
            //延迟1秒
          }, 1000);
          if (jsonData.result) {
            if (jsonData.data != null) {
              let deviceNo = jsonData.data.deviceNo;
              self.formModel.deviceNo = deviceNo;
            }
          }
        });
      }
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      self.loading = true;

      if (self.businessKey.length == 0) {
        return deviceInfoApi.create();
      } else {
        return deviceInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          if (self.businessKey.length == 0){
            self.formModel.columnColor="1";
            // self.formModel.columnHeight="";
            self.formModel.moduleType="2";
            self.formModel.deviceType="1";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },

  components: {
    "el-select-tree": SelectTree
  }
};
</script>