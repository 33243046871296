<template>
  <el-dialog
    :visible.sync="showDialog"
    title="查看关联单位"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="970px"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-form
        ref="queryForm"
        :model="queryModel"
        inline
        class="demo-form-inline"
        label-width="100px"
      >
        <el-form-item label="单位名称" prop="companyName">
          <el-input type="text" size="mini" v-model="queryModel.companyName" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >查询</el-button>&nbsp;
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="companyName" label="单位名称" width="180"></el-table-column>
        <el-table-column prop="isBind" label="关联状态" width="120">
          <template slot-scope="{row}">{{row.isBind == 0? "未关联" : "已关联"}}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button size="mini" type="danger" @click="handleUnBound(row)">解除关联</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import deviceInfoApi from "@/api/base/deviceInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css";  // progress bar style

export default {
  props: ["deviceId"],
  data() {
    var self = this;

    return {
      formModel: {},
      queryModel: {
        companyName: ""
      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
      loading: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("deviceId", self.deviceId);
      
      if(self.queryModel.companyName!=null){
        formData.append("companyName", self.queryModel.companyName);
      }
      
      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      deviceInfoApi
        .queryBindCompanyList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleUnBound(record) {
      //取消关联
      var self = this;

      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true
      }).then(() => {
        self.loading = true;
        var formData = new FormData();
        formData.append("companyId", record.companyId);
        formData.append("deviceId", self.deviceId);

        return deviceInfoApi.unbindCompany(formData).then(function(response) {
          self.loading = false;
          var jsonData = response.data;
          self.changePage(self.pageIndex);
          if (jsonData.result) {
            self.$message({
              message: "解绑成功!",
              type: "success"
            });
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
          }
        });
      });
    }
  },
  mounted: function() {
    this.changePage(1);
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>